.MainLogin2 {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../Images//Back/Temp3.webp");
  background-size: cover;
}
.MainLogin2 div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}
.Block-1 div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 300px;
}
.Block-1 div img {
  width: 350px;
  height: auto;
  border-radius: 30px;
}
.Block-1 div h1 {
  color: #fff;
  font-size: 50px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
}
.Block-1 div p {
  color: #fff;
  font-size: 30px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
}

.Block-2 .inBlock2 {
  border-radius: 50px;
  background: #32323297;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 600px;
  height: 750px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 70px;
}
.Block-2 .inBlock2 h1 {
  color: #fff;
  font-size: 50px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
}

.Block-2 .inBlock2 .Inputs {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.Inputs input {
  color: #000;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
  font-size: 20px;
  width: 300px;
  height: 40px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  outline: none;
}
.Inputs button {
  border-radius: 15px;
  background: #3cba99;
  width: 250px;
  height: 40px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 25px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
  cursor: pointer;
}
.Inputs p {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100px;
  font-style: normal;
  font-size: 15px;
}
.Inputs p a {
  color: #3cba99;
  cursor: pointer;
  text-decoration: none;
}
.Block-2 a {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
  cursor: pointer;
  text-decoration: none;
}
.Block-2 .google-signin-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.google-signin-button:hover {
  background-color: #357ae8;
}

.google-signin-button:active {
  background-color: #3367d6;
}

@media only screen and (max-width: 1400px) {
  .MainLogin2 {
    flex-direction: column;
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 20px;
  }
  .MainLogin2 .Block-1 {
    width: 100%;
    height: fit-content;
  }
  .MainLogin2 .Block-2 {
    width: 100%;
  }
  .MainLogin2 .Block-1 div {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }
  .MainLogin2 .Block-1 div p {
    width: fit-content;
    text-align: center;
  }
}
@media only screen and (max-width: 720px) {
  .MainLogin2 .Block-2 .inBlock2 {
    width: 95%;
    height: fit-content;
    padding: 20px 0 20px 0;
  }
  .MainLogin2 .Block-1 div img {
    width: 250px;
  }
  .MainLogin2 .Block-1 div h1 {
    font-size: 40px;
  }
  .MainLogin2 .Block-1 div p {
    font-size: 20px;
  }
}
