/* Success.css */

.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
  color: #fff;
  text-align: center;
  gap: 100px;
}

.success-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #3cba99;
}

.success-container button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #3cba99;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}

.success-container button:hover {
  background-color: #04694e;
}
