.Sec-2 {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 20px;
}
.Sec-2 .SB1 {
  width: 80%;
  height: 100%;
  background: #6f6f6f;
  background: url("../../Images/Temp3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SB1 h1 {
  color: #fff;
  font-size: 60px;
  width: 70%;
}
.SB1 p {
  color: #fff;
  width: 70%;
  font-size: 20px;
}
.SB1 :nth-child(1) {
  width: 70%;
  font-size: 25px;
  text-decoration: underline;
  text-decoration-color: #3cba99;
  text-underline-offset: 5px;
  text-decoration-thickness: 3px;
}
.SB1 button {
  width: 200px;
  height: 50px;
  color: #fff;
  background: #3cba99;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
  margin-top: 50px;
}

@media only screen and (max-width: 960px) {
  .Sec-2 {
    flex-direction: column;
    height: fit-content;
    padding: 20px 0 20px 0;
  }
  .Sec-2 .SB1 {
    width: 100%;
    min-height: 400px;
    padding: 20px 0 20px 0;
  }
  .SB1 h1 {
    width: 100%;
    text-align: center;
  }
  .SB1 :nth-child(1) {
    width: 100%;
    text-align: center;
  }
  .SB1 p {
    width: 100%;
    text-align: start;
    padding: 10px;
  }
}
