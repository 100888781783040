.choose-category {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  gap: 50px;
  padding: 20px 0 20px 0;
}
.choose-category h1 {
  color: #3cba99;
  font-size: 35px;
}

.category-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: fit-content;
  padding: 20px 0 20px 0;
  flex-direction: column;
  gap: 20px;
}

.category-container button {
  width: 350px;
  height: 40px;
  text-align: start;
  color: #000000;
  padding: 5px;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: #3cba99 2px solid;
  cursor: pointer;
}
.category-container button:hover {
  background: #3cba99;
  color: #fff;
  border-radius: 5px;
  border-bottom: 0px;
}
