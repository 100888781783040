.Sec-4 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 0 10px 0 10px;
  margin-top: 40px;
  flex-direction: column;
  background: #000;
}

.Sec-4 img {
  width: 350px;
  height: auto;
  object-fit: cover;
  flex-shrink: 0;
}

.Sec-4 p {
  color: #fff;
}
.Sec-4 a {
  color: #3cba99;
}
