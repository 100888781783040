.Options {
  width: 95%;
  height: fit-content;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 20px;
}
.Options h1 {
  color: #3cba99;
}
.Options input {
  width: 400px;
  height: 35px;
  border: none;
  border: 1px #3cba99 solid;
  border-radius: 5px;
  outline: none;
}
.Options button {
  width: 350px;
  height: 40px;
  text-align: start;
  color: #000000;
  padding: 5px;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  border-radius: 0;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: #3cba99 2px solid;
  cursor: pointer;
}

.Selections::-webkit-scrollbar {
  height: 8px; /* WebKit Browsers */
}

.Selections::-webkit-scrollbar-thumb {
  background-color: #ccc; /* WebKit Browsers */
  border-radius: 10px;
}

.Selections select {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.Selections select:hover {
  border-color: #3cba99;
}
