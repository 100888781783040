.MainCart {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.MainCart h1 {
  text-decoration: underline;
  text-decoration-color: #3cba99;
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
}

.Fetch-Products {
  width: 100%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 50px;
}

.cart-products {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  height: fit-content;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.product-card2 {
  width: 100%;
  height: 80px;
  padding-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #ccc;
}

.product-card2 img {
  width: 80px;
  height: auto;
  border-radius: 8px;
}

.product-card2 h3 {
  font-size: 18px;
  margin: 0;
}

.product-card2 p {
  font-size: 14px;
  color: #555555;
  margin: 0;
}

.product-card2 {
  width: 80%;
  height: 80px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #ccc;
}

.product-card2 button {
  background: red;
  border: none;
  border-radius: 5px;
  width: 30px;
  cursor: pointer;
  height: 30px;
  margin-left: auto;
}

.Payments {
  width: 500px;
  height: fit-content;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.Payments h2 {
  color: #3cba99;
}

.Payments .Info-Inputs {
  width: 100%;
  height: fit-content;
  padding: 20px 0 20px 0;
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.Info-Inputs input {
  width: 200px;
  height: 30px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
}
.Payments-Btns {
  width: 100%;
  height: fit-content;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
.Payments-Btns button {
  width: 100%;
  height: 35px;
  color: #fff;
  background: #3cba99;
  border: none;
  outline: none;
  border-radius: 5px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
}
.Payments .Terms {
  width: 100%;
  height: fit-content;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .cart-products {
    width: 100%;
    padding: 5px;
  }

  .product-card2 {
    width: 100%;
    padding: 10px;
  }

  .product-card2 img {
    width: 60px;
    height: 60px;
  }

  .product-card2 h3 {
    font-size: 16px;
  }

  .product-card2 p {
    font-size: 12px;
  }
  .Info-Inputs input {
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    font-size: 16px;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
  }
}
