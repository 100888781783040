.Sec-1 {
  width: 100%;
  height: 600px;
  background: url("../../Images/Back/Temp1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  overflow: hidden;
  background-position: center;
  animation: zoom 20s infinite;
  -webkit-animation: zoom 20s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Sec-1 div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 200px;
}
.Sec-1 div div {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.Sec-1 div div button {
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #3cba99;
  border: none;
  outline: none;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
}

.Sec-1 h1 {
  color: #fff;
  font-size: 85px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
}
.Sec-1 p {
  color: #fff;
  font-size: 40px;
  width: 450px;
  text-overflow: clip;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
}

@keyframes zoom {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 110%;
  }
  100% {
    background-size: 100%;
  }
}

@media only screen and (max-width: 1500px) {
  .Sec-1 {
    background-size: cover !important;
  }
  .Sec-1 div {
    padding-left: 50px;
  }
}
@media only screen and (max-width: 650px) {
  .Sec-1 div div {
    gap: 20px;
  }
  .Sec-1 h1 {
    font-size: 60px;
  }
  .Sec-1 p {
    font-size: 30px;
    width: 300px;
  }
}

@media only screen and (max-width: 460px) {
  .Sec-1 {
    height: 500px;
    background-size: cover !important;
  }
  .Sec-1 div {
    padding-left: 10px;
  }
}
