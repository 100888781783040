.Sec-3 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 50px 0 50px 0;
  gap: 20px;
}

.Sec-3 h1 {
  font-family: "Rubik", sans-serif;
  font-size: 40px;
  font-optical-sizing: auto;
  font-weight: 500px;
  font-style: normal;
}

.Sec-3 button {
  width: 250px;
  height: 50px;
  color: #fff;
  background: #3cba99;
  border: none;
  cursor: pointer;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  border-radius: 15px;
  font-style: normal;
}

.Sec-3 .Sec-3-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Sec-3 .product-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Sec-3 .product-card img {
  width: auto;
  height: 250px;
  border-radius: 5px;
  object-fit: cover;
}

.Sec-3 .product-card h3 {
  font-size: 15px;
  margin: 10px 0;
  text-align: center;
}

.Sec-3 .product-card button {
  background-color: #3cba99;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
  height: 40px;
}

.Sec-3 .product-card button:hover {
  background-color: #349185;
}

.Sec-3-in button {
  width: 200px;
  height: 50px;
  color: #3cba99;
  border: none;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500px;
  border-radius: 0;
  font-style: normal;
}

.Sec-3-in button:hover {
  border-bottom: 2px #3cba99 solid;
}
.Sec-3 .product-card .Procardb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

@media only screen and (max-width: 460px) {
  .Sec-3 .product-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 170px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Sec-3 .product-card img {
    width: auto;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
  }
  .Sec-3 .product-card .Procardb {
    flex-direction: column;
    gap: 0;
  }
}
