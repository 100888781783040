.ProductView-1 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: auto;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 150px;
}

@media (min-width: 768px) {
  .ProductView-1 {
    flex-direction: row;
    padding-top: 150px;
  }
}

.View1-Images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.View1-Images .main-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: 400px;
  object-fit: contain;
  margin-bottom: 10px;
  transition: transform 0.5s ease;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.View1-Images .main-image:hover {
  transform: scale(1.2);
  cursor: zoom-out;
}

.View1-Images .main-image:hover {
  transform: scale(1.5);
  margin-top: 50px;
}

.View1-Images .thumbnail-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.View1-Images .thumbnail-images img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.View1-Images .thumbnail-images img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.View1-Images .thumbnail-images img.active {
  border: 2px solid #3cba99;
}

.View2-Content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px;
  border-radius: 15px;
  border: 1px #dcdcdc solid;
  width: 550px;
  overflow: hidden;
  height: fit-content;
}

.View2-Content h1 {
  font-size: 1.5rem;
  line-height: 50px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
  text-align: start;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: #3cba99;
  text-underline-offset: 10px;
}

.View2-Content p {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #555;
  text-align: start;
  text-overflow: clip;
  inline-size: 100%;
  overflow-wrap: break-word;
}
.View2-Content button {
  background-color: #3cba99;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
  height: 40px;
}
.View2-Content button:hover {
  background-color: #349185;
}

.product-price {
  font-size: 1.5rem;
  color: #3cba99;
  margin-top: 10px;
  text-align: center;
}

.product-price strong {
  color: #333;
}

.MoreProducts {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: column;
}
.MoreProducts h1 {
  font-size: 30px;
  text-decoration: underline;
  text-decoration-color: #3cba99;
  text-underline-offset: 10px;
  text-decoration-thickness: 3px;
}

/* CSS for the quantity input */
.ProductView-1 .View2-Content input[type="number"] {
  width: 60px; /* Adjust width as needed */
  height: 40px;
  padding: 5px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #3cba99;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.ProductView-1 .View2-Content input[type="number"]:focus {
  border-color: #2a8a72;
  box-shadow: 0 0 5px rgba(60, 186, 153, 0.5);
}

.ProductView-1 .View2-Content input[type="number"]::-webkit-inner-spin-button,
.ProductView-1 .View2-Content input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ProductView-1 .View2-Content input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 768px) {
  .View2-Content {
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    gap: 10px;
  }
  .ProductView-1 {
    gap: 10px;
  }
}
