.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 450px;
  background: #000000e0;
  gap: 20px;
  border-radius: 15px;
  box-shadow: 1px 0 18px rgba(0, 0, 0, 0.2);
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.popup-inner .ht {
  position: absolute;
  top: 16px;
  font-size: 20px;
  color: #3cba99;
  font-family: "Rubik", sans-serif;
}
.images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.popup-inner img {
  width: 70px;
  height: auto;
}

.popup-inner a {
  font-optical-sizing: auto;
  text-decoration: none;
  color: #fff;
  font-size: 15px;
}
.popup-inner p {
  font-weight: bold;
  color: #fff;
  position: relative;
  font-size: 15px;
  top: 50px;
}
.Maind {
  width: 80%;
}

@media only screen and (max-width: 460px) {
  .popup-inner {
    width: 90%;
  }
}
